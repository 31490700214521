import env from '../env.js'
import { getUserData } from '@/helpers/utils'

const state = {
    isLoading: false,
    AppActiveUser: getUserData(),
    domain: env.domain,
    localUserKey: env.localUserKey,
    appName: env.appName,
    config:{
        shortDesc: "Supporters group"
    },
    windowWidth: null,
    countries: [],
    localGovernments: [],
    banks:[],
    dashboardData:{
        "groups": {
            "total": 0,
            "pending": 0,
            "approved": 0,
        },
        "members": {
            "total": 0
        },
        "users": {
            "total": 0,
            "admin": 0,
            "grouphead": 0,
            "staff": 0,
            "verified_users": 0,
            "unverified_users": 0,
        },
    },
    lightbox:{
        index: null,
        visible: false,
        files: []
    },
}

export default state
